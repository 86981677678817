import React from "react";

// creating functional component ans getting props from app.js and destucturing them
export default function StepTwo({ nextStep, handleFormData, prevStep, values }) {
  //creating error state for validation

  // console.log(values.goalNumber1);

  // after form submit validating the form data using validator
  const submitFormData = (e) => {
    e.preventDefault();
      
    // console.log(values);
    handleFormData({});

    nextStep();

  };

  return (
    <div>
      <form onSubmit={submitFormData}>

        <h2 className='mb-5 text-center text-capitalize'>What are we doing</h2>

        <h5>What are the main reasons for this project?</h5>

        <p className="col-12 col-md-12">
          <label htmlFor="goalNumber1" className='form-label'>Goal #1</label>
          <textarea 
            className="form-control" 
            name="goalNumber1"
            id="goalNumber1"
            defaultValue={values.goalNumber1}
            rows="3"
            onChange={handleFormData("goalNumber1")}
          />
        </p>

        <p className="col-12 col-md-12">
          <label htmlFor="goalNumber2" className='form-label'>Goal #2</label>
          <textarea 
            className="form-control"
            name="goalNumber2"
            id="goalNumber2"
            defaultValue={values.goalNumber2}
            rows="3"
            onChange={handleFormData("goalNumber2")}
          />
        </p>

        <p className="col-12 col-md-12">
          <label htmlFor="goalNumber3" className='form-label'>Goal #3</label>
          <textarea 
            className="form-control" 
            name="goalNumber3"
            id="goalNumber3"
            defaultValue={values.goalNumber3}
            rows="3"
            onChange={handleFormData("goalNumber3")}
          />
        </p>

        <p className="col-12 col-md-12">
          <label htmlFor="goalNumber4" className='form-label'>Goal #4</label>
          <textarea 
            className="form-control" 
            name="goalNumber4"
            id="goalNumber4"
            defaultValue={values.goalNumber4}
            rows="3"
            onChange={handleFormData("goalNumber4")}
          />
        </p>

        <p className="col-12 col-md-12">
          <label htmlFor="goalNumber5" className='form-label'>Goal #5</label>
          <textarea 
            className="form-control" 
            name="goalNumber5"
            id="goalNumber5"
            defaultValue={values.goalNumber5}
            rows="3"
            onChange={handleFormData("goalNumber5")}
          />
        </p>

        <div className='col-12 text-center'>
          <button type="submit" onClick={prevStep} className="btn btn-orange default-padding me-3 shadow text-capitalize" style={{ width: '150px' }}> Previous </button>
          <button type="submit" className="btn btn-primary default-padding shadow text-capitalize" style={{ width: '150px' }}> Continue </button>
        </div>
      </form>
    </div>
  );
};
